<template>
  <div class="main-card mb-3 card pa-0 ma-0">
    <div class="card-body pa-0">
      <div
        :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
        class="card-title card-header-tab card-header"
      >
        <div
          class="card-header-title font-size-lg text-capitalize font-weight-normal"
        >
          {{ $t('settings.lang_templates') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">
          <v-dialog v-model="dialog" max-width="600px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="createEntry"
                class="elevation-0"
                color="primary"
                dark
                fab
                small
              >
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title
                :class="[$vuetify.theme.dark ? 'dark-bg' : '']"
                class="card-header"
              >
                <span class="headline">{{ this.formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-form ref="form" lazy-validation>
                  <v-row align="center" justify="center">
                    <v-col class="pb-0 mb-0" cols="12">
                      <v-text-field
                        v-model="form.receiptTmplName"
                        :rules="[(v) => !!v]"
                        dense
                        :label="this.$t('erp.lang_TemplateName')"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col class="pb-0 mb-0" cols="12">
                      <v-select
                        v-model="form.printerPaperSize"
                        :items="widths"
                        :rules="[(v) => !!v]"
                        dense
                        item-text="name"
                        item-value="id"
                        :label="
                          this.$t('settings.lang_printerManagerPaperType')
                        "
                        outlined
                        required
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>

              <v-card-actions
                :class="[$vuetify.theme.dark ? 'dark-bg' : '']"
                class="card-footer text-right"
              >
                <v-col class="text-right pa-0 ma-0" cols="12">
                  <v-spacer />
                  <v-btn color="error" @click="close">
                    {{ this.$t('generic.lang_cancel') }}
                  </v-btn>
                  <v-btn
                    v-if="!edit"
                    :disabled="loading"
                    :loading="loading"
                    color="primary"
                    @click="create()"
                    >{{ this.$t('generic.lang_create') }}
                  </v-btn>
                  <v-btn
                    v-if="!edit"
                    :disabled="loading"
                    :loading="loading"
                    color="primary"
                    outlined
                    @click="create(true)"
                    >{{ this.$t('erp.lang_createAndGoToItem') }}
                  </v-btn>
                  <v-btn
                    v-if="edit"
                    :disabled="loading"
                    :loading="loading"
                    color="primary"
                    @click="update()"
                    >{{ this.$t('generic.lang_save') }}
                  </v-btn>

                  <v-btn
                    v-if="edit"
                    :disabled="loading"
                    :loading="loading"
                    color="primary"
                    outlined
                    @click="update(true)"
                    >{{ this.$t('generic.lang_save_and_go_to') }}
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <v-divider class="pa-0 ma-0" />

      <v-row>
        <v-col class="pt-0" cols="12">
          <Datatable
            ref="templates"
            :api-endpoint="ENDPOINTS.DATATABLES.PRINTSETTINGS.TEMPLATES"
            :datatable-headers="this.datatableHeaders"
            :elevation="0"
            :excel-columns="excelColumns"
            :permissionDelete="true"
            :permissionEdit="true"
            :v-show="true"
            excel-file-name="Receipt templates"
            show-delete-buttons
            show-display-buttons
            show-edit-buttons
            show-select-checkbox
            @deleteEntries="deleteEntries"
            @deleteEntry="deleteEntry"
            @displayEntry="displayEntry"
            @editEntry="editEntry"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { ENDPOINTS } from '@/config';
import { Events } from '@/plugins/events';
import Datatable from '@/components/datatable/Datatable';

export default {
  name: 'TemplatesComponent',
  components: {
    Datatable,
  },
  data: () => {
    return {
      ENDPOINTS,
      form: {
        receiptTmplName: '',
        printerPaperSize: '',
      },
      defaultTMPL: {
        id: '',
        receiptTmplName: '',
        printerPaperSize: '',
      },
      widths: [
        {
          id: '50',
          name: '50 mm',
        },
        {
          id: '80',
          name: '80 mm',
        },
      ],
      dialog: false,
      loading: false,
      edit: false,
    };
  },
  computed: {
    datatableHeaders: function () {
      return [
        {
          text: '#',
          value: 'id',
          hide: true,
        },
        {
          text: this.$t('generic.lang_name'),
          value: 'receiptTmplName',
          sortable: false,
        },
        {
          text: this.$t('generic.lang_type'),
          value: 'type',
          sortable: false,
        },
        {
          text: this.$t('settings.lang_printerManagerPaperType'),
          value: 'printerPaperSize',
        },
      ];
    },
    excelColumns: function () {
      return [
        {
          label: '#',
          field: 'id',
        },
        {
          label: this.$t('generic.lang_name'),
          field: 'receiptTmplName',
        },
        {
          text: this.$t('generic.lang_type'),
          value: 'type',
          sortable: false,
        },
        {
          label: this.$t('settings.lang_printerManagerPaperType'),
          field: 'printerPaperSize',
        },
      ];
    },
    formTitle: function () {
      return this.edit
        ? this.$t('erp.lang_editTemplate')
        : this.$t('settings.lang_createTemplate');
    },
  },
  methods: {
    close() {
      this.resetForm();
      this.dialog = false;
    },
    resetForm() {
      this.form = Object.assign({}, this.defaultTMPL);
      this.$refs.form.reset();
    },
    update(goTo = false) {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.form.printTmplId = this.form.id;
      this.form.tmplName = this.form.receiptTmplName;
      this.form.updateType = 'info';
      this.axios
        .post(ENDPOINTS.PRINTSETTINGS.TEMPLATES.UPDATE, this.form)
        .then((res) => {
          if (res.data.success) {
            Events.$emit('showSnackbar', {
              message: this.$t('erp.lang_itemSuccessfulAdded'),
              color: 'success',
            });
            this.$refs.form.reset();
            this.dialog = false;

            if (goTo) {
              this.$router.push(
                '/settings/printerSettings/receiptTmpl/details/' +
                  this.form.printTmplId
              );
            } else {
              this.$refs.templates.getDataFromApi();
              this.$refs.templates.resetSelectedRows();
            }
          } else if (!res.data.success) {
            Events.$emit('showSnackbar', {
              color: 'error',
              message: res.data.msg,
            });
          } else {
            Events.$emit('showSnackbar', {
              color: 'error',
              message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            color: 'error',
            message: err.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create(goToCreated = false) {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.axios
        .post(ENDPOINTS.PRINTSETTINGS.TEMPLATES.CREATE, this.form)
        .then((res) => {
          if (res.data.status === 'SUCCESS') {
            Events.$emit('showSnackbar', {
              message: this.$t('erp.lang_itemSuccessfulAdded'),
              color: 'success',
            });
            this.$refs.form.reset();
            this.dialog = false;

            if (goToCreated) {
              this.$router.push(
                '/settings/printerSettings/receiptTmpl/details/' +
                  res.data.tmplID
              );
            } else {
              this.$refs.templates.getDataFromApi();
              this.$refs.templates.resetSelectedRows();
            }
          } else {
            Events.$emit('showSnackbar', {
              color: 'error',
              message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            color: 'error',
            message: err.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    delete(idsToDelete) {
      this.$swal({
        title: this.$t('erp.lang_ware_delete_confirm'),
        text: this.$t('erp.lang_ware_delete_confirm'),
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios
            .post(ENDPOINTS.PRINTSETTINGS.TEMPLATES.DELETE, {
              deleteTmplIDs: idsToDelete,
            })
            .then((res) => {
              if (res.data.success) {
                Events.$emit('showSnackbar', {
                  message: this.$t('erp.lang_itemSuccessfulDeleted'),
                  color: 'success',
                });

                this.$refs.templates.getDataFromApi();
                this.$refs.templates.resetSelectedRows();
              } else if (res.data.STATUS === 'FAILED') {
                Events.$emit('showSnackbar', {
                  color: 'error',
                  message: res.data.msg,
                });
              } else {
                Events.$emit('showSnackbar', {
                  color: 'error',
                  message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
                });
              }
            })
            .catch((err) => {
              Events.$emit('showSnackbar', {
                color: 'error',
                message: err.message,
              });
            });
        },
      });
    },
    deleteEntry(entry) {
      this.delete([entry.id]);
    },
    deleteEntries(ids) {
      this.delete(ids);
    },
    editEntry(entry) {
      this.edit = true;
      this.form = Object.assign({}, entry);
      this.dialog = true;
    },
    createEntry() {
      this.edit = false;
      this.form = Object.assign({}, this.defaultTMPL);
      this.dialog = true;
    },
    displayEntry(entry) {
      this.$router.push(
        '/settings/printerSettings/receiptTmpl/details/' + entry.id
      );
    },
  },
};
</script>

<style scoped></style>
